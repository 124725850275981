import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, combineLatest, Observable, of, Subject, timer } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { control, Control, FullscreenOptions, latLng, Map, MapOptions, TileLayer } from 'leaflet';
import 'src/app/modules/shared/components/map/controls/view-entire-trip-control/view-entire-trip-control.js';
import 'src/app/modules/shared/components/map/controls/follow-asset-control/follow-asset-control.js';
import 'src/app/modules/shared/components/map/controls/timeline-control/timeline-control.js';
import { LeafletService } from '@app/modules/location/services/leaflet.service';
import {
  GTCxTileLayerOptions,
  LeafletConfigService
} from '@app/modules/location/services/leaflet-layer-configs/leaflet-config.service';
// we need this polyfill for Safari < v13 or else we get reference error for ResizeObserver
import { ResizeObserver } from 'resize-observer';
import { SettingsApiService } from '@app/services/settings-api.service';
import { defaultMapCoordinates, MapSettings } from '@app/modules/location/models/settings.model';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { PlatformFacade } from '@app/modules/platform/facade/platform.facade';
import { FeatureToggleService } from '@app/modules/feature-toggles/services/feature-toggle.service';
import { environment as env, environment } from '@environments/environment';
import { LeafletZoneService } from '@app/modules/zones/services/leaflet-zone.service';
import { HereTrafficService } from '@app/modules/location/services/here-traffic.service';
import { MAP_OPTIONS_TILE_LAYER_NAMES } from './controls/map-options-menu/map-options-menu.model';
import { EventTrackingFacade, TrackedEvents } from '@app/modules/location/facade/event-tracking.facade';
import { DataDogService } from '@app/services/data-dog.service';
import { LocationApiService } from '@app/modules/location-client/location-api.service';
import { FiltersService } from '@app/services/filters.service';
import { FiltersState } from '@app/services/filters.model';
import { selectViewContext, selectViewSubContext } from '@app/store/layout/selectors/layout.selectors';
import { Store } from '@ngrx/store';
import { postBodyConfigFromFilters } from '@app/modules/location-client/utilities';
import { DetailsSubcontext } from '@app/store/layout/reducers/layout.reducer';
import { LoadingAnimationService } from '@app/services/loading-animation.service';

declare module 'leaflet' {
  interface Control {
    _addTo(map: Map): Control;
  }

  interface Map {
    _leaflet_id: number;
    _container: HTMLElement;
  }
}

export enum LoadMapAssetsEvent {
  FILTER = 'filter',
  ZOOM = 'zoom',
  DRAG = 'drag',
  INTERVAL = 'interval'
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MapComponent implements OnDestroy, OnInit {
  constructor(
    public leafletService: LeafletService,
    private leafletConfig: LeafletConfigService,
    public platformFacade: PlatformFacade,
    private settingsService: SettingsApiService,
    public featureToggleService: FeatureToggleService,
    private leafletZoneService: LeafletZoneService,
    private hereTrafficService: HereTrafficService,
    private router: Router,
    private pendo: EventTrackingFacade,
    private datadogService: DataDogService,
    public locationApiService: LocationApiService,
    private store: Store,
    private loadingAnimationService: LoadingAnimationService,
    private filtersService: FiltersService
  ) {
    this.platformFacade
      .getIsMobile()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(flag => (this.isMobile = flag === true ? true : false));
    this.featureToggleService
      .isFeatureEnabled('maps-incident-button-enabled')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(featureFlag => {
        this.incidentButtonEnabled = featureFlag === true ? true : false;
        this.showIncidentButton.next(this.incidentButtonEnabled && !this.isMobile);
      });
  }

  @Output() map$: EventEmitter<Map> = new EventEmitter();
  @Output() mapAssetsLoadState$: EventEmitter<String> = new EventEmitter();
  @Input() mapId = 'map-container';

  public map: Map;
  public zoom: number;
  public onDestroy$ = new Subject();
  public locateControlOptions = new BehaviorSubject<Control.LocateOptions>(
    this.leafletConfig.defaultLocateControlOptions
  );
  public fullscreenControlOptions = new BehaviorSubject<FullscreenOptions>(
    this.leafletConfig.defaultFullscreenControlOptions
  );
  public settingsLoadState = new BehaviorSubject<ResourceLoadState>(ResourceLoadState.INITIAL);
  public loadSuccessful = ResourceLoadState.LOAD_SUCCESSFUL;
  public region = env.region;
  public newOptions: MapOptions = this.leafletConfig.defaultMapOptions;
  public isMobile = false;
  public incidentButtonEnabled = true;
  public showIncidentButton = new BehaviorSubject<boolean>(false);
  private mapResizeObserver = new ResizeObserver(() => {
    this.map?.invalidateSize();
  });

  trafficOverlay = this.hereTrafficService.getTrafficOverlay();

  layer: TileLayer = this.leafletConfig.tileLayers.NORMAL_DAY;
  mapOptionsCheckboxesVisible: boolean = false;
  clusterEnabled: boolean;
  incidentsEnabled: boolean;
  trafficEnabled: boolean;
  zonesEnabled: boolean = false;

  isMapOptionsMenuOpen = false;
  settingsMap;
  $url = new BehaviorSubject<string>(this.router.url);
  private cancelRequest$ = new Subject<void>();
  assets = [];
  loadMapAssetsEvent$ = new Subject<LoadMapAssetsEvent>();

  classicLayerSelected: boolean = false;
  satelliteLayerSelected: boolean = false;

  ngOnInit() {
    this.leafletConfig
      .getTranslatedControlOptions$('locate')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(options => {
        this.locateControlOptions.next(options);
      });

    this.leafletConfig
      .getTranslatedControlOptions$('fullscreen')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(options => {
        this.fullscreenControlOptions.next(options);
      });

    this.settingsLoadState.next(ResourceLoadState.LOADING);
    this.settingsService
      .getAllSettings()
      .pipe(
        switchMap(settingsMap => {
          this.settingsMap = settingsMap;
          if (settingsMap.has(MapSettings.MAP_LAYER)) {
            const layerSetting = settingsMap.get(MapSettings.MAP_LAYER);
            // eslint-disable-next-line no-unused-expressions
            Object.keys(this.leafletConfig.tileLayers).includes(layerSetting.value)
              ? (this.newOptions.layers = [this.leafletConfig.tileLayers[layerSetting.value]])
              : (this.newOptions.layers = [this.leafletConfig.tileLayers.NORMAL_DAY]);

            this.setBorderOnSelectedLayer(this.newOptions.layers[0] as TileLayer);
            this.layer = this.newOptions.layers[0] as TileLayer;
          }
          return of(settingsMap);
        })
      )
      .subscribe(_ => {
        this.settingsLoadState.next(ResourceLoadState.LOAD_SUCCESSFUL);
      });

    // Combine all events and filter state with polling interval
    combineLatest([
      this.filtersService.getFiltersState().pipe(
        filter(filters => filters.companyId != null),
        distinctUntilChanged((prev, curr) => {
          const prevFilter = { ...prev, sortOrder: undefined, sortAttribute: undefined };
          const currFilter = { ...curr, sortOrder: undefined, sortAttribute: undefined };
          return JSON.stringify(prevFilter) === JSON.stringify(currFilter);
        }),
        tap(_ => {
          this.loadMapAssetsEvent$.next(LoadMapAssetsEvent.FILTER);
        })
      ),
      this.loadMapAssetsEvent$,
      this.store.select(selectViewSubContext),
      this.store.select(selectViewContext)
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([filtersState, loadMapAssetsEvent, viewSubContext, viewContext]) => {
        const calculatedPollingInterval = this.calculatePollingInterval(this.map.getZoom());
        if (viewSubContext == DetailsSubcontext.HISTORY) {
          // ViewContext.LIST was returning undefined?!
          this.cancelRequest$.next();
          return;
        }
        this.loadAssetsForCompany(filtersState, calculatedPollingInterval, loadMapAssetsEvent);
      });

    // this subscription will keep checkbox display and setting up to date if zone display changes from another component ie filters bar
    this.leafletZoneService.getZonesEnabled().subscribe(ze => {
      if (ze != this.zonesEnabled) {
        this.zonesEnabled = ze;
        if (ze) {
          this.settingsService.saveSetting(MapSettings.MAP_ZONES, 'true').pipe(take(1)).subscribe();
        } else {
          this.settingsService.saveSetting(MapSettings.MAP_ZONES, 'false').pipe(take(1)).subscribe();
        }
      }
    });
  }

  loadAssetsForCompany(filters: FiltersState, pollingInterval: number, loadMapAssetsEvent: LoadMapAssetsEvent): void {
    // Cancel any in-flight requests or timers
    this.cancelRequest$.next();
    this.assets = [];
    this.mapAssetsLoadState$.emit(ResourceLoadState.LOADING);
    const postBodyConfig = postBodyConfigFromFilters(filters, {
      pageSize: environment?.apiRequestPageSize || 3000
    });
    delete postBodyConfig?.sortBy;
    delete postBodyConfig?.sortOrder;
    delete postBodyConfig?.northEast;
    delete postBodyConfig?.southWest;

    this.locationApiService
      .getAssets(postBodyConfig, true)
      .pipe(
        switchMap(assets => {
          this.mapAssetsLoadState$.emit(ResourceLoadState.LOAD_SUCCESSFUL);
          this.assets = assets;
          this.leafletService.refreshMap(assets);

          if (loadMapAssetsEvent === LoadMapAssetsEvent.FILTER && !this.router.url.includes('live')) {
            this.leafletService.zoomToAssets(assets);
          }

          // loading spinner should only show on initial asset load
          this.loadingAnimationService.shouldShowAssetsLoadingAnimations = false;

          // Return an object with the assets and the next event type
          return of({ assets, nextEvent: LoadMapAssetsEvent.INTERVAL });
        }),
        switchMap(({ assets, nextEvent }) =>
          timer(pollingInterval).pipe(
            map(() => ({ assets, nextEvent })),
            takeUntil(this.cancelRequest$)
          )
        ),
        takeUntil(this.cancelRequest$)
      )
      .subscribe({
        next: ({ assets, nextEvent }) => {
          // Call the method again with the updated event type
          this.loadAssetsForCompany(filters, pollingInterval, nextEvent);
        },
        error: (error: any) => {
          console.error('Failed to load assets:', error);
          this.mapAssetsLoadState$.emit(ResourceLoadState.LOAD_FAILURE);
        }
      });
  }

  clearLayersHistoryView() {
    this.leafletService.clusterLayer.disableClustering();
    this.leafletService.handleIncidentOverlayRemove();
    this.trafficOverlay.removeFrom(this.map);
  }

  onLayerChange(newLayer: string) {
    const tempLayer: TileLayer = this.leafletConfig.tileLayers[newLayer];

    this.setBorderOnSelectedLayer(tempLayer);

    if (tempLayer == this.layer) return;
    this.map.addLayer(tempLayer);
    this.map.removeLayer(this.layer);
    this.layer = tempLayer;
    this.settingsService.saveSetting(MapSettings.MAP_LAYER, newLayer).pipe(take(1)).subscribe();
  }

  onClusterEnabledChange(enabled: boolean) {
    this.clusterEnabled = enabled;
    if (enabled) {
      this.leafletService.clusterLayer.enableClustering();
      this.settingsService.saveSetting(MapSettings.MAP_CLUSTERING, 'true').pipe(take(1)).subscribe();
    } else {
      this.leafletService.clusterLayer.disableClustering();
      this.settingsService.saveSetting(MapSettings.MAP_CLUSTERING, 'false').pipe(take(1)).subscribe();
    }
  }

  onIncidentsEnabledChange(enabled: boolean) {
    this.incidentsEnabled = enabled;
    if (enabled) {
      this.leafletService.handleIncidentOverlayAdd();
      this.settingsService.saveSetting(MapSettings.MAP_INCIDENTS, 'true').pipe(take(1)).subscribe();
    } else {
      this.leafletService.handleIncidentOverlayRemove();
      this.settingsService.saveSetting(MapSettings.MAP_INCIDENTS, 'false').pipe(take(1)).subscribe();
    }
  }

  onTrafficEnabledChange(enabled: boolean) {
    this.trafficEnabled = enabled;
    if (enabled) {
      this.trafficOverlay.addTo(this.map);
      this.settingsService.saveSetting(MapSettings.MAP_TRAFFIC, 'true').pipe(take(1)).subscribe();
    } else {
      this.trafficOverlay.removeFrom(this.map);
      this.settingsService.saveSetting(MapSettings.MAP_TRAFFIC, 'false').pipe(take(1)).subscribe();
    }
  }

  onZonesEnabledChange(enabled: boolean) {
    this.zonesEnabled = enabled;
    if (enabled) {
      this.leafletZoneService.addZonesToMap();
      this.settingsService.saveSetting(MapSettings.MAP_ZONES, 'true').pipe(take(1)).subscribe();
    } else {
      this.leafletZoneService.clearZoneFeatureGroup();
      this.settingsService.saveSetting(MapSettings.MAP_ZONES, 'false').pipe(take(1)).subscribe();
    }
  }

  setFeatureGroupsFromSettings(settingsMap: any) {
    // Cluster Assets
    if (settingsMap.has(MapSettings.MAP_CLUSTERING)) {
      const clusterSetting = settingsMap.get(MapSettings.MAP_CLUSTERING).value === 'true' ? true : false;
      this.onClusterEnabledChange(clusterSetting);
    }

    // Road Incidents
    if (settingsMap.has(MapSettings.MAP_INCIDENTS)) {
      const incidentsSetting = settingsMap.get(MapSettings.MAP_INCIDENTS).value === 'true' ? true : false;
      this.onIncidentsEnabledChange(incidentsSetting);
    }

    // Traffic
    if (settingsMap.has(MapSettings.MAP_TRAFFIC)) {
      const trafficSetting = settingsMap.get(MapSettings.MAP_TRAFFIC).value === 'true' ? true : false;
      this.onTrafficEnabledChange(trafficSetting);
    }

    // Zones
    if (settingsMap.has(MapSettings.MAP_ZONES)) {
      const zonesSetting = settingsMap.get(MapSettings.MAP_ZONES).value === 'true' ? true : false;
      this.onZonesEnabledChange(zonesSetting);
    }
  }

  setBorderOnSelectedLayer(layer: TileLayer) {
    const layerName = (layer.options as GTCxTileLayerOptions).name;

    if (!layerName) {
      throw new Error('setBorderOnSelectedLayer layer has no name');
    }

    if (layerName === MAP_OPTIONS_TILE_LAYER_NAMES.CLASSIC) {
      this.classicLayerSelected = true;
      this.satelliteLayerSelected = false;
    } else {
      this.satelliteLayerSelected = true;
      this.classicLayerSelected = false;
    }
  }

  ngOnDestroy(): void {
    this.map?.clearAllEventListeners();
    this.map?.remove();

    this.mapResizeObserver.disconnect();

    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  onMapReady(map: Map) {
    this.map = map;
    this.map$.emit(map);
    this.leafletZoneService.setMap(map);
    if (!this.router.url.includes('live')) {
      this.map.setView(latLng(defaultMapCoordinates[this.region].lat, defaultMapCoordinates[this.region].long), 4);
    }

    // send pendo track event for whether Zones are enabled on map load
    if (this.settingsMap.has(MapSettings.MAP_ZONES)) {
      const pendoEventName =
        this.settingsMap.get(MapSettings.MAP_ZONES).value === 'true'
          ? TrackedEvents.ZONES_ENABLED_ON_APP_LOAD
          : TrackedEvents.ZONES_DISABLED_ON_APP_LOAD;
      this.pendo.trackEvent(pendoEventName);
    }

    // set visibility of feature groups from settings
    // this must happen after we emit map to leaflet service since presently the cluster layer is built in the receiveMap function
    this.setFeatureGroupsFromSettings(this.settingsMap);

    // set up a subscription comparing the current url w/ previous url
    this.$url.pipe(pairwise(), takeUntil(this.onDestroy$)).subscribe(urls => {
      // if we are in history view, hide checkboxes and disable some feature groups
      if (urls[1].includes('/history')) {
        this.mapOptionsCheckboxesVisible = false;
        this.clearLayersHistoryView();

        // otherwise, check if we just came from history to any other url - if so, restore from settings
      } else if (urls[0].includes('/history')) {
        this.mapOptionsCheckboxesVisible = true;
        this.setFeatureGroupsFromSettings(this.settingsMap);
        // else make sure checkboxes are showing for initial load in live view
      } else {
        this.mapOptionsCheckboxesVisible = true;
      }
    });

    // since pairwise above needs two events to emit, push again so it fires on app load
    this.$url.next(this.router.url);

    // pipe navigation changes to the above to make it work
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.onDestroy$)
      )
      .subscribe(_ => {
        const url = this.router.url;
        this.$url.next(url);
      });

    // add attribution to map
    this.leafletConfig
      .getTranslatedControlOptions$('attribution')
      .pipe(take(1))
      .subscribe(translated => {
        control.attribution().addAttribution(translated).addTo(this.map);
      });

    // remove the attribution prefix (leaflet and flag links)
    this.map.attributionControl?.setPrefix(false);

    // add scale control to map
    control.scale(this.leafletConfig.getLocalizedScaleOptions()).addTo(this.map);

    // add zoom control to map
    this.leafletConfig
      .getTranslatedControlOptions$('zoom')
      .pipe(take(1))
      .subscribe(options => {
        control.zoom(options).addTo(this.map);
      });

    const mapContainer = document.getElementById(this.mapId);
    if (mapContainer) {
      this.mapResizeObserver.observe(mapContainer);
    }
    this.featureToggleService
      .isFeatureEnabled('rum-context-dump-enabled')
      .pipe(
        filter(isFeatureEnabled => Boolean(isFeatureEnabled)),
        switchMap(_ => {
          return this.datadogService.getRumContextDumpData();
        })
      )
      .subscribe(contextDumpData => {
        const { currentCompany, divisions, divisionsCount } = contextDumpData;
        delete contextDumpData.currentCompany;
        delete contextDumpData.divisions;
        delete contextDumpData.divisionsCount;
        this.datadogService.addRumAction('context_dump', contextDumpData);
        this.datadogService.setContextProperty('companyDivisions', {
          company: currentCompany,
          divisions,
          divisionsCount
        });
      });

    const mapZoomed$ = new Observable(observer => {
      this.map.on('zoomend', () => observer.next('zoom'));
    }).pipe(debounceTime(250));

    mapZoomed$.subscribe((eventType: LoadMapAssetsEvent) => {
      this.loadMapAssetsEvent$.next(eventType);
    });
  }

  // returns polling interval based on zoom level
  // 14  = 3000 ft
  // 13  = 1 mi
  // 12  = 3 mi
  // 11  = 5 mi
  // 10  = 10 mi
  // 9   = 20 mi
  // 8   = 50 mi
  // 7   = 100 mi
  // 6   = 200 mi
  // 5   = 300 mi
  // 4   = 500 mi
  // 3   = 1000 mi
  // 2.5 = 2000 mi
  // 2   = 3000 mi
  //
  calculatePollingInterval(zoomLevel: number): number {
    if (zoomLevel < 3) {
      return 60_000;
    } else if (zoomLevel <= 4) {
      return 30_000;
    } else {
      return environment.liveUpdate.pollingInterval;
    }
  }

  toggleMapOptionsMenu(event) {
    this.isMapOptionsMenuOpen = event;
  }

  onSearchControlZoneClicked() {
    if (!this.zonesEnabled) this.onZonesEnabledChange(true);
  }
}
